<template>
  <div :key="key" class="admin-layout">
    <Navbar />
    <router-view />
    <transition name="fade">
      <Sidebar
        v-if="sidebarName"
        :component-name="sidebarName"
        :component-styles="componentStyles"
        :sidebar-styles="sidebarStyles"
        :sidebar-position="sidebarPosition"
      />
    </transition>
  </div>
</template>

<script>
import Navbar from '@/views/Admin/NavbarAdmin'
import Sidebar from '@/components/Sidebar'

export default {
  name: 'adminLayout',
  components: {
    Navbar,
    Sidebar
  },
  data: () => ({
    sidebarName: null,
    sidebarStyles: null,
    sidebarRotation: 'right',
    componentStyles: null,
    animationName: 'sidebar',
    key: 0
  }),
  mounted() {
    // this.$root.$on('ShowSidebar', params => {
    //   setTimeout(() => {
    //     const innerParams = []
    //     if (params === null) {
    //       this.sidebarName = params
    //     } else if (typeof params === 'string') {
    //       innerParams.push(params)
    //     } else if (typeof params === 'object') {
    //       innerParams.push(...params)
    //     }

    //     this.sidebarName = innerParams[0]
    //     this.sidebarStyles = innerParams[1]
    //     this.componentStyles = innerParams[2]
    //   }, 0)
    //   this.key += 1
    // })
    this.$root.$on('ShowSidebar', params => {
      setTimeout(() => {
        if (params === null) {
          this.sidebarName = params
        } else if (typeof params === 'string') {
          this.sidebarName = params
        } else if (typeof params === 'object') {
          this.sidebarName = params.sidebarName
          this.sidebarStyles = params.sidebarStyles
          this.componentStyles = params.componentStyles
          this.animationName = !params.animationName ? 'sidebar' : params.animationName
        }
        this.sidebarPosition = !params?.sidebarPosition ? 'right' : params.sidebarPosition
      }, 0)
      this.key += 1
    })
  }
}
</script>
