<template>
  <div class="sidebar__wrapper" @click.self="$root.$emit('ShowSidebar', null)">
    <div class="sidebar__inner" :style="sidebarStyles" :class="{'sidebar__inner--left': sidebarPosition === 'left'}">
      <component :is="componentName" />
    </div>
  </div>
</template>

<script>
import AddDriver from '@/views/Admin/Drivers/AddDriver'
import AddShift from '@/views/Admin/Shifts/AddShift'
import AddService from '@/views/Admin/Services/AddService'
import AddCustomer from '@/views/Admin/Customers/AddCustomer'
import MapParams from '@/views/Admin/Map/MapParams'
import MapFilter from '@/views/Admin/Map/MapFilter'

export default {
  name: 'Sidebar',
  components: {
    AddDriver,
    AddShift,
    AddService,
    AddCustomer,
    MapParams,
    MapFilter
  },
  props: {
    componentName: {
      type: String,
      default: null
    },
    componentStyles: {
      type: Object,
      default: null
    },
    sidebarStyles: {
      type: String,
      default: null
    },
    sidebarPosition: {
      type: String,
      default: 'right'
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
