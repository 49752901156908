import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {layout: 'admin', auth: true},
    component: () => import('@/views/Home')
  },
  {
    path: '/saas',
    name: 'AdminCompanies',
    meta: {layout: 'superAdmin', auth: true},
    component: () => import('@/views/SuperAdmin/Companies')
  },
  {
    path: '/drivers',
    name: 'Drivers',
    meta: {layout: 'admin', auth: true},
    component: () => import('@/views/Admin/Drivers')
  },
  {
    path: '/shifts',
    name: 'Shifts',
    meta: {layout: 'admin', auth: true},
    component: () => import('@/views/Admin/Shifts')
  },
  {
    path: '/services',
    name: 'Services',
    meta: {layout: 'admin', auth: true},
    component: () => import('@/views/Admin/Services')
  },
  {
    path: '/customers',
    name: 'Customers',
    meta: {layout: 'admin', auth: true},
    component: () => import('@/views/Admin/Customers')
  },
  {
    path: '/community/',
    name: 'Community',
    meta: {layout: 'admin', auth: true},
    component: () => import('@/views/Admin/Community'),
  },
  {
    path: '/community/:questionID',
    name: 'Question',
    meta: {layout: 'admin', auth: true},
    component: () => import('@/views/Admin/Community/Question')
  },
  {
    path: '/map',
    name: 'Map',
    meta: {layout: 'admin', auth: true},
    component: () => import('@/views/Admin/Map')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
