<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import mainLayout from '@/layouts/mainLayout'
import adminLayout from '@/layouts/adminLayout'
import superAdminLayout from '@/layouts/superAdminLayout'

export default {
  components: {
    mainLayout,
    adminLayout,
    superAdminLayout
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || 'main') + '-layout'
    }
  },
  created() {
    this.$modal.open = function() {
      document.body.style.position = 'fixed'
      document.body.style.top = `-${window.scrollY}px`
    }
    this.$modal.close = function() {
      const scrollY = document.body.style.top
      document.body.style.position = ''
      document.body.style.top = ''
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
    }
    this.$modal.styles = {
      classes: 'modal',
      adaptive: true
      // width: '92%',
      // maxWidth: 900,
      // height: '92%',
      // maxHeight: 900,
    }
    //   this.$modal.stylesRight = {
    //     classes: 'modalSidebar',
    //     width: '600px',
    //     height: '100%',
    //     adaptive: true,
    //     shiftX: 1,
    //     shiftY: 1
    //   }
  }
}
</script>

<style lang="scss">
@import './assets/styles/style.scss';
</style>
