<template>
  <div class="superAdmin-layout">
    <Navbar />
    <router-view />
  </div>
</template>

<script>
import Navbar from '@/views/SuperAdmin/NavbarSuperAdmin'

export default {
  name: 'SuperAdminLayout',
  components: {Navbar}
}
</script>
