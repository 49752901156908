<template>
  <div class="sidebar__main">
    <div class="sidebar__title">Add New Service</div>
    <div class="sidebar__row">
      <div class="sidebar__row-title">Basic Data</div>
      <div class="sidebar__row-main">
        <label class="field__label">
          <div class="field__title">Service Name</div>
          <input class="field__input" type="text" />
        </label>
        <label class="field__label">
          <div class="field__title">Price</div>
          <input class="field__input" type="text" />
        </label>
      </div>
    </div>
    <div class="sidebar__row">
      <div class="sidebar__row-title">Availability for Shifts</div>
      <div class="sidebar__row-shifts">
        <div class="sidebar__row-shift" v-for="shift in shifts" :key="shift.id">
          <div class="shift__title">{{ shift.name }}</div>
          <div class="shift__switch">
            <ToggleButton color="#0290FC" :width="40" v-model="shift.active"/>
          </div>
        </div>
      </div>
    </div>
    <div class="sidebar__row sidebar__row--last">
      <div class="sidebar__row-title">Extras</div>
      <div class="sidebar__row-surcharge">
        <div class="surcharge__title">Fuel Surcharge</div>
        <div class="surcharge__switch">
          <ToggleButton color="#0290FC" :width="40" v-model="surcharge"/>
        </div>
      </div>
    </div>
    <div class="sidebar__footer">
      <div class="sidebar__footer-inner">
        <div class="button button--fill">Save</div>
        <div class="button" @click="$root.$emit('ShowSidebar', null)">Cancel</div>
      </div>
    </div>
  </div>
</template>

<script>
import {ToggleButton} from 'vue-js-toggle-button'

export default {
  name: 'AddShift',
  components: {ToggleButton},
  data: () => ({
    shifts: [
      {
        id: 1,
        name: '🌙  Night Shift',
        active: false
      },
      {
        id: 2,
        name: '️‍🌈  Pride Shift',
        active: false
      },
      {
        id: 3,
        name: 'North Shift',
        active: false
      },
      {
        id: 4,
        name: '🌙  Night Shift',
        active: false
      },
      {
        id: 5,
        name: '🌙  Night Shift',
        active: false
      },
      {
        id: 6,
        name: '️‍🌈  Pride Shift',
        active: false
      },
      {
        id: 7,
        name: 'North Shift',
        active: false
      },
      {
        id: 8,
        name: '🌙  Night Shift',
        active: false
      }
    ],
    surcharge: false
  })
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
