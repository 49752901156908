<template>
  <div class="navbar__wrapper">
    <div class="container">
      <div class="navbar__inner">
        <div class="navbar__left">
          <div class="navbar__logo">
            <div class="navbar__logo-inner"></div>
          </div>
          <div class="navbar__title">
            <span class="navbar__title--blue">STS Delivery </span>
            <span>Solutions</span>
          </div>
        </div>
        <div class="navbar__center">
          <nav class="navbar__navigation">
            <ul class="nav__list">
              <router-link
                tag="li"
                class="nav__item"
                to="/saas"
                active-class="nav__item--active"
              >saas</router-link>
            </ul>
          </nav>
          <button class="button button--orange">Marketplace</button>
          <button class="button button--blue">Community</button>
        </div>
        <div class="navbar__right">
          <div class="letter navbar__letter">{{ letter }}</div>
          <div class="navbar__name">{{ name }}</div>
          <div class="navbar__actions" v-html="icons.dots"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import icons from '@/assets/icons/icons'
export default {
  name: 'Navbaradmin',
  data: () => ({
    name: 'Brett Ostrander',
    letter: 'B',
    icons
  })
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
