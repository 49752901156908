<template>
  <div class="addDriver__wrapper">
    <div class="addDriver__title">Add New User — 🚗 Driver</div>
    <div class="addDriver__row">
      <div class="addDriver__row-title">Basic Data</div>
      <div class="addDriver__row-main">
        <label class="field__label">
          <div class="field__title">User Name</div>
          <input class="field__input" type="text" />
        </label>
        <label class="field__label">
          <div class="field__title">Phone Number</div>
          <input class="field__input" type="text" />
        </label>
        <label class="field__label">
          <div class="field__title">E-mail</div>
          <input class="field__input" type="text" />
        </label>
        <label class="field__label">
          <div class="field__title">Password</div>
          <input class="field__input" type="text" />
        </label>
      </div>
    </div>
    <div class="addDriver__row addDriver__row-last">
      <div class="addDriver__row-title">Availability for Shifts</div>
      <div class="addDriver__row-shifts">
        <div class="addDriver__row-shift" v-for="shift in shifts" :key="shift.id">
          <div class="shift__title">{{ shift.name }}</div>
          <div class="shift__switch">
            <ToggleButton color="#0290FC" :width="40" v-model="shift.active"/>
          </div>
        </div>
      </div>
    </div>
    <div class="addDriver__footer">
      <div class="addDriver__footer-inner">
        <div class="button button--fill">Save</div>
        <div class="button" @click="$root.$emit('ShowSidebar', null)">Cancel</div>
      </div>
    </div>
  </div>
</template>

<script>
import {ToggleButton} from 'vue-js-toggle-button'

export default {
  name: 'AddDriver',
  components: {ToggleButton},
  data: () => ({
    shifts: [
      {
        id: 1,
        name: '🌙  Night Shift',
        active: false
      },
      {
        id: 2,
        name: '️‍🌈  Pride Shift',
        active: false
      },
      {
        id: 3,
        name: 'North Shift',
        active: false
      },
      {
        id: 4,
        name: '🌙  Night Shift',
        active: false
      }
    ]
  })
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
